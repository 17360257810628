@import '~@travel-ui/styles/src/utility';

$wrapper-spacing: $spacing-40;

.wrapper {
  @include media(sp) {
    padding-top: $spacing-16;
  }

  @include media(tl, pc) {
    padding: $wrapper-spacing 0;
  }
}

.wrapperTop {
  @include media(tl, pc) {
    &#{&} {
      padding: 0 0 $wrapper-spacing;
    }
  }
}
