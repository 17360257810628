@import '~@travel-ui/styles/src/utility';

.autoComplete {
  position: relative;
  word-break: break-all;

  .suggestionsContainer {
    border-radius: 0 0 $border-radius $border-radius;
    position: absolute;
    width: 100%;
    z-index: $zIndex-dropdown;

    @include box-shadow-middle;
  }

  ul {
    margin-left: 0;
  }

  li {
    list-style-type: none;
  }

  .suggestion {
    border: 1px solid $pastel-gray;
    border-top: none;
    background-color: $white;
    color: $jet;
    cursor: pointer;
    height: 45px;
    outline: none;
    padding: 0 10px;
    text-align: left;
    width: 100%;
    font-size: $font-venus;
  }

  .highlightedSuggestion {
    background-color: #068500; // temporary
    color: $white;
  }

  &:last-child {
    border-radius: 0 0 $border-radius $border-radius;

    .suggestion {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}
