@import '~@travel-ui/styles/src/utility';
@import '../../../../core/App/app.module.scss';

$item-count--sp: 2;
$item-count--tl: 4;
$item-count--pc: 6;

$article-height--tl-pc: 248px;

.container {
  margin: 40px 0;

  @include media(sp) {
    margin: 30px $spacing-m 40px $spacing-m;
  }

  @include media(pc) {
    margin: $spacing-l 0 0;
  }

  .itemSize {
    width: 100%;
    // from figma: 135px(image height) / 375(screen size)
    height: calc((135 / 375) * 100vw);

    @include media(sp) {
      margin-bottom: $spacing-l;
    }

    @include media(tl, pc) {
      height: calc(#{$article-height--tl-pc});
    }

    // for overwriting css
    > div {
      width: 100%;
      // from figma: 135px(image height) / 375(screen size)
      height: calc((135 / 375) * 100vw);

      @include media(tl, pc) {
        height: initial;
      }
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  .image > svg {
    margin: 0 auto;
  }

  .roundedImage {
    border-radius: 12px;
  }

  .cardContainer {
    width: inherit;
    display: flex;
    flex-flow: column nowrap;

    @include media(tl) {
      width: calc((100vw / #{$item-count--tl}));
      padding-bottom: $spacing-xl;
      margin-left: $spacing-m;
      margin-right: $spacing-base;
      flex: 0 0 auto;
    }

    @include media(pc) {
      width: calc((#{$max-content-width--pc} - (#{$spacing-24 * 5})) / 6);
      padding-bottom: $spacing-xl;
      margin-right: $spacing-l;
      flex: 0 0 auto;
    }
  }

  .scrollableSectionItem {
    &:last-child > .cardContainer {
      margin-right: $spacing-m;
    }

    &:first-child > .cardContainer {
      margin-left: $spacing-m;
    }
  }

  .loader {
    width: 100%;
    @include media(sp, tl) {
      height: 150px;
    }
  }

  .header {
    display: block;
    @include font-title;
    margin-bottom: $spacing-m;
    color: $jet;

    @include media(sp) {
      @include font-sub-title;
      font-weight: 600;
    }

    @include media(tl) {
      margin-left: $spacing-m;
    }
  }

  .recommendationsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media(tl) {
      width: 100vw;
    }
  }
}

.containerArea {
  @include media(tl) {
    padding-top: $spacing-l;
  }

  @include media(tl, pc) {
    margin: 0;
  }
}

.tileContainer {
  display: flex;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  @include media(sp) {
    margin: 0 0 $spacing-base 0;
  }

  @include media(tl, pc) {
    flex-flow: column nowrap;
  }

  .article {
    width: 100%;

    @include media(sp) {
      :nth-child(n) {
        border-radius: $border-radius-8px;
      }
    }

    @include media(tl, pc) {
      height: $article-height--tl-pc;
    }
  }

  .text {
    width: 100%;
    font-weight: bold;
    font-size: $font-venus;
    line-height: $line-height-title-sp;
    color: $white;
    z-index: 1;

    @include media(tl, pc) {
      color: $jet;
      font-size: $font-neptune;
    }
  }

  .underCardInfo {
    margin-top: 8px;
    height: 24px;
  }

  .imageWrapper {
    width: 100%;
    height: 100%;

    @include media(tl, pc) {
      height: $article-height--tl-pc;
    }

    .image {
      overflow: hidden;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.85;
    }
  }
}

.carousel {
  margin: unset;
  max-width: unset;
}

.carouselButton {
  transform: translateY(-60px);
}

.animationWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.loadMoreBox {
  display: flex;
  justify-content: center;
  padding-top: $spacing-base;

  @include media(tl, pc) {
    border-top: 1px solid $isabelline;
  }

  .iconTextLink {
    @include font-category;

    @include media(sp) {
      font-size: $font-venus;
    }

    .expandIcon {
      transform: rotate(0);
      transition: 0.3s;

      &.showMore {
        transform: rotate(180deg);
      }
    }
  }

  .loadMoreButton {
    width: 100%;
  }
}
