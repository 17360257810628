@import '~@travel-ui/styles/src/utility';

.list {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  .item {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
}
