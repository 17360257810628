@import '~@travel-ui/styles/src/utility';

.mainButton {
  @include resetButtonStyle;

  width: 100%;
  display: flex;
  align-items: center;
  background: $white;
  color: $jet;
  padding: 10px;
  font-size: $font-venus;
  border-radius: 3px;
  cursor: pointer;

  .placeholder {
    @include text-truncate(1);
    color: $sonic-silver;
    text-align: left;
  }

  .destination {
    @include text-truncate(1);
    text-align: start;
  }
}

.mainButtonTopAndArea {
  height: 48px;

  @include media(sp) {
    border: 1px solid $cloudy-gray;
    @include border-radius-all($border-radius-6px);
  }
  @include media(tl) {
    border: 1px solid $cloudy-gray;
    border-radius: $border-radius-8px;
  }

  &.hasError {
    @include media(sp, tl) {
      border-color: $apple-red;
    }
  }
}

.topAreaButtonError {
  &#{&} {
    @include media(sp) {
      padding: $spacing-04 0 0;
    }
  }
}

.searchIcon {
  margin-right: 5px;
  flex-shrink: 0; /* To not make this icon look smaller when text is too long */
}

.mainButtonProviderInfo {
  &#{&} {
    height: 40px;
  }
}

.mainButtonProviderList {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 $spacing-base;
  font-weight: normal;

  color: $jet;
  border-radius: 4px;
  border: 1px solid $cloudy-gray;

  // search icon
  svg > g > g > path {
    fill: $jet;
  }

  @include media(pc, tl) {
    height: 44px;
    max-width: 100%;
  }

  > div {
    justify-content: flex-start;
    width: 100%;
  }
}

.placeInputAutoComplete {
  width: 100%;
}

.searchInput {
  font-size: $font-venus;
  cursor: pointer;

  &.withHover {
    @include media(pc) {
      &#{&}#{&}#{&} {
        border-color: $cloudy-gray;
        border-radius: $border-radius-4px;

        &:hover {
          border-color: $lincoln-green;
        }
      }
    }
  }

  &.withHoverTopAndArea {
    @include media(pc) {
      &#{&}#{&}#{&} {
        height: 48px;
        border-color: $cloudy-gray;
        border-radius: $border-radius-8px;

        &:hover {
          border-color: $lincoln-green;
        }
      }
    }
  }

  &.hasError {
    @include media(pc) {
      &#{&}#{&}#{&}#{&} {
        border-color: $apple-red;
      }
    }
  }

  &.providerInfoStyle {
    @include media(pc, tl) {
      &#{&}#{&}#{&} {
        height: 40px;
      }
    }
  }
}

.withoutBorder {
  &#{&}#{&}#{&} {
    border: none;
  }
}

.pcSuggestionContainer {
  max-height: 300px;
  background-color: $white;
  overflow-y: auto;
  position: absolute;
  z-index: $zIndex-dropdown;
  margin-top: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: $border-radius;

  &.active {
    width: 100%;
  }
}

.recentSearchLabel {
  @include font-category;
  margin: $spacing-base;
}

.suggestion {
  @include resetButtonStyle;

  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $isabelline;
  text-align: left;
  font-size: $font-venus;
  color: $jet;
  line-height: $line-height-label;
  padding: 12px 0;

  &:hover,
  &.highlighted {
    background-color: $shady-white;
  }
}

.suggestionIcon,
.savedSearchIcon {
  margin: 0 $spacing-base;
}

.savedSearchIcon {
  align-self: flex-start;
}

.savedSearchPlace {
  @include text-truncate(2);
  margin-right: $spacing-16;
}

.deleteSavedSearch {
  @include resetButtonStyle;
  margin-left: auto;
  margin-right: $spacing-m;
}

.suggestText {
  pointer-events: none;
  padding: $spacing-m;
}

.savedSearchQuery {
  font-size: $font-mars;
  color: $sonic-silver;
}

.placeLabel {
  @include text-truncate;
}

.dialogClassName {
  @include box(100%);
}

.dialogHeader {
  @include media(tl) {
    & > h3 {
      text-align: center;
    }
  }
}

.dialogContent {
  @include media(sp) {
    padding: $spacing-m;
  }

  @include media(tl) {
    padding: 40px 120px;
    height: 100%;
    max-height: 100%;
  }
}

.skeletonItem {
  &#{&} {
    padding: $spacing-base $spacing-m;
    align-items: center;
    height: 55px;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .skeletonImageItem {
      @include box(20px);
    }

    div > div {
      // paragraph > skeleton block
      // To force every skeleton block to be full-width
      width: 100%;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $isabelline;
    }
  }
}

.emptyStateWrapper {
  @include flex-center;
  flex-direction: column;
  padding: 114px $spacing-m 0;
  word-break: break-word;

  @include media(pc) {
    padding: 40px 60px;
  }

  @include media(tl) {
    padding: 60px 0;
  }

  .hotelIcon {
    margin-bottom: $spacing-m;
  }

  .title {
    @include font-label;
    margin-bottom: $spacing-base;

    @include media(tl) {
      margin-bottom: 12px;
    }

    @include media(sp) {
      font-size: $font-uranus;
    }
  }

  .description {
    @include font-body;
    color: $sonic-silver;
    text-align: center;
  }
}

.errorMessage {
  margin: $spacing-s 0;
}
