@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

$inactive-form-filter-area: 40px;
$searchForm-z-index: $z-index-header - 2;

.searchForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-right: $spacing-16;
  }

  @include media(pc) {
    @include pc-layout;
    padding-top: $spacing-16;
    padding-bottom: $spacing-16;
  }
  @include media(tl) {
    padding: $spacing-16;
  }
  @include media(tl, pc) {
    flex-wrap: nowrap;
  }

  @include media(sp) {
    display: block;
    > div > *:not(:last-child) {
      margin-bottom: $spacing-08;
    }
  }
}

.hasError {
  padding-bottom: 0;
}

.topAreaSearchForm {
  margin-top: $spacing-16;

  > *:not(:last-child) {
    margin-right: $spacing-08;
  }

  @include media(pc, tl) {
    padding: 0;
  }

  @include media(sp) {
    display: block;
    > div > *:not(:last-child) {
      margin-bottom: $spacing-08;
    }
  }
} 

.placeInput {
  max-width: 100%;
  @include media(tl, pc) {
    margin-bottom: 0;
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
  @include media(tl) {
    flex-shrink: 1;
  }
}

.amountInput {
  @include media(tl) {
    min-width: 0;
  }

  @include media(sp) {
    flex-basis: 100%;
  }
}

.dateInput {
  @include media(sp) {
    margin-bottom: $spacing-08;
  }
}

.providerDateInput {
  @include media(tl, pc) {
    flex-basis: 100%;
    max-width: 25%;
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.submitButton {
  min-height: 40px;
  height: 44px;
  width: 144px;
  max-width: 100%;
  border-radius: $border-radius-4px;
}

.errorMessageWrapper {
  &#{&} {
    padding: 0;
    margin: $spacing-08 0 0;
    background: none;

    @include media(sp) {
      margin-bottom: $spacing-08;
    }
  }

  .errorMessage {
    font-weight: normal;
  }
}

.overlay {
  @include resetButtonStyle;
  @include box(100%);
  position: fixed;
  height: 100vh;
  z-index: $searchForm-z-index - 1;;
}

.amountInput {
  &#{&} {
    > button > div {
      @include text-truncate;
    }
  }
}

.topAreaSubmitButton {
  height: 48px;
  width: 155px;
  border-radius: $border-radius-8px;
  @include media(pc, tl) {
    min-width: fit-content;
  }

  @include media(sp) {
    width: 100%;
  }

  @include media(tl) {
    width: auto;
  }
}
