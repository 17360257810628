@import '~@travel-ui/styles/src/utility';
@import '../../../../core/App/app.module.scss';

.article {
  width: calc((#{$max-content-width--pc} - (#{$spacing-16 * 3})) / 4);
  flex-shrink: 0;
  max-height: 204px;

  @include media(sp) {
    padding: 0 0 $spacing-m $spacing-m;
    width: initial;
    max-height: none;
  }

  @include media(tl) {
    margin: 0 0 $spacing-xl $spacing-m;
  }

  @include media(pc) {
    margin: 0 $spacing-m $spacing-m 0;
  }
}

.carousel {
  margin: unset;
  max-width: unset;
}

.carouselButton {
  transform: translateY(-67px);
}

.articlesContainer {
  display: flex;
  flex-wrap: wrap;

  @include media(sp) {
    display: block;
  }
}

.title {
  @include font-title;
  color: $jet;
  padding-bottom: $spacing-m;

  @include media(tl) {
    padding-top: $spacing-m;
    margin-left: $spacing-m;
  }

  @include media(sp) {
    @include font-sub-title;
    font-weight: 600;
    margin-left: $spacing-m;
  }
}

.wrapper {
  margin-top: $spacing-m;

  @include media(pc) {
    margin: $spacing-m 0 28px;
  }

  @include media(sp) {
    margin-right: 0;
    margin-left: 0;
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
  }
}

.loaderWrapper {
  padding-top: $spacing-m;

  .loaderImage {
    @include box(30vw, 100px);

    @include media(tl, pc) {
      @include box(100%, 150px);
    }
  }
}

.animationWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.loadMoreBox {
  display: flex;
  justify-content: center;
  padding-top: $spacing-base;

  @include media(tl, pc) {
    border-top: 1px solid $isabelline;
  }

  .iconTextLink {
    @include font-category;

    @include media(sp) {
      font-size: $font-venus;
    }

    .expandIcon {
      transform: rotate(0);
      transition: 0.3s;

      &.showMore {
        transform: rotate(180deg);
      }
    }
  }
}

.list {
  display: flex;

  @include media(tl, pc) {
    flex-wrap: wrap;
    width: 100%;
  }

  @include media(sp) {
    overflow-x: scroll;
  }
}

.scrollableItemWrapper {
  @include disableScrollBar-x;
}

.scrollableItem {
  &:last-child {
    margin-right: $spacing-m;
  }
}
