@import '~@travel-ui/styles/src/utility';

.contentBox {
  font-size: $font-venus;

  @include media(sp) {
    display: flex;
    flex-direction: column;
    color: $black;
    font-weight: normal;
    padding-top: $spacing-base;
  }
}

.iconBox {
  margin-left: 15px;
}

.iconTextLink {
  color: $cilantro;
  margin-top: $spacing-s;
}

.image {
  object-fit: cover;
  border-radius: $border-radius-8px;
  display: block;
  width: 100%;
  height: calc(100vw / 6.73);

  @include media(tl) {
    height: calc(100vw / 5.953);
  }

  @include media(tl, pc) {
    max-height: 152px;
  }

  @include media(sp) {
    @include box(calc(100vw / 2.667));
    // from figma: 184px, 138px (image size) / 375px(screen size)
    width: calc((184 / 375) * 100vw);
    height: calc((138 / 375) * 100vw);
    flex-shrink: 0;
  }
}

.link {
  text-decoration: none;
}

.titleBox {
  display: flex;
  margin-top: $spacing-s;

  &.withBottomSpacing {
    margin-bottom: $spacing-base;
  }
}

.title {
  @include font-label;
  font-weight: bold;
  color: $white;

  @include media(tl, pc) {
    color: $jet;
    @include text-truncate(2);
  }
}

.subtitle {
  @include font-sub-body;
  font-weight: normal;
}

.wrapper {
  @include break-word;

  @include media(sp) {
    padding-bottom: $spacing-base;
  }
}

.backgroundWrapper {
  position: relative;
  width: calc((188 / 375) * 100vw);
  height: calc((223 / 375) * 100vw);
  border-radius: $border-radius-8px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius-8px;
}

.floating {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  position: absolute;
  color: $white;
  bottom: 0;
  left: 0;
  padding: $spacing-m;
  padding-bottom: $spacing-base;
  font-weight: bold;
  font-size: $font-mars;
  border-radius: $border-radius-8px;
  width: 100%;
}
