@import '~@travel-ui/styles/src/utility';

.emailSubscriptionWrapper,
.emailSubscriptionContent {
  margin-bottom: $spacing-l;
  background-color: $shady-white;
  padding: $spacing-24;
  border-radius: $spacing-hl;

  max-height: 225px;

  @include media(pc) {
    width: 360px;
    margin-left: $spacing-08;
  }

  @include media(tl, sp) {
    width: 100%;
    margin: 0 $spacing-16 $spacing-24;
  }

  .titleWrapper {
    display: flex;

    @include media(tl, sp) {
      padding-bottom: $spacing-08;
      align-items: center;
    }
  }

  .titleIcon {
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    margin-left: $spacing-08;
    font-size: $font-saturn;
    font-weight: 600;
    color: $lincoln-green;
    margin-top: auto;
    margin-bottom: auto;

    @include media(tl, sp) {
      font-size: $font-jupiter;
      font-weight: 700;
    }
  }

  .description {
    font-size: $font-neptune;
    color: $jet;
    line-height: $line-height-sub-body;
  }
}

.subscriptionArrowWrapper {
  display: block;
  min-width: 38px;
  min-height: 38px;
  right: 3px !important;
}

.subscriptionContent {
  @include media(pc) {
    width: 100%;
  }
}

.formWrapper,
.subscriptionContent {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;

  .inputFormWrapper {
    // input + error message
    height: 72px;
  }

  .emailInput {
    width: 100%;
    padding-bottom: $spacing-16;
  }

  .inputAlert {
    background-color: transparent;
    padding: $spacing-base 0 0;
    margin-bottom: 0;
  }

  .inputAlertTitleWrapper {
    margin: 0;
  }

  .inputAlertTitle {
    font-weight: normal;
    margin: 0;
    color: $apple-red;
  }

  .subscribeButton {
    width: 100%;
    max-width: 148px;

    @include media(sp) {
      max-width: none;
    }
  }
}

.emailSubscriptionContent {
  @include media(pc) {
    min-width: 360px;
    margin-left: $spacing-24;
  }

  .subscribeButton {
    width: 100%;

    @include media(sp) {
      max-width: none;
    }
  }

  .inputArrowBtn {
    min-width: 38px;
    min-height: 38px;
    height: 100%;
    background-color: $cilantro;
    border: none;
    border-radius: 4px;
    padding: 12px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .signInButton {
    width: 100%;
    background-color: $cilantro;
    height: 36px;
    max-width: 148px;
    border-radius: 4px;
    font-size: $font-venus;

    @include media(sp) {
      width: 100%;
      max-width: none;
      font-size: $font-neptune;
    }
  }

  .emailInput {
    width: 310px;
    padding-bottom: 0;

    @include media(tl) {
      width: 100%;
    }
  }
}

.serverErrorAlert {
  padding: $spacing-08 $spacing-08 0;
  margin: $spacing-04 0 0;
  border-radius: $spacing-04;
  background: transparent;
}

.messageWrapper {
  border-radius: $spacing-s;

  &#{&} {
    margin: 0;
  }
}

.successMessage {
  color: $lincoln-green;

  &#{&} {
    font-weight: normal;
  }
}

.alertIcon {
  &#{&} {
    align-items: flex-start;
  }
}
