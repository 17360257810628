@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

// please update accordingly if there is any changes on js
$tl-image-height: 129px;
$tl-image-width: 172px;
$pc-image-height: 161px;
$pc-image-width: 278px;

.title {
  font-size: $font-solar;
  margin-bottom: $spacing-m;

  @include media(sp, tl) {
    margin-left: $spacing-m;
  }

  @include media(sp) {
    font-weight: 600;
    font-size: $font-uranus;
  }
}

.skeletonItem {
  width: 100%;
  height: $tl-image-height;

  @include media(pc) {
    height: $pc-image-height;
  }
}

.bannerItem {
  @include box4by3(calc(100vw / 1.65));
  flex-shrink: 0;
  margin-right: $spacing-m;

  @include media(pc) {
    @include box16by9(calc((#{$max-content-width--pc} - (#{$spacing-16 * 3})) / 4));
  }

  @include media(tl) {
    @include box16by9(calc(100vw / 3.465));
    margin-right: 0;
    margin-left: $spacing-m;
  }

  @include media(sp) {
    &:first-child {
      margin-left: $spacing-m;
    }
  }
}

.scrollableSectionItem {
  @include media(tl) {
    &:last-child {
      padding-right: $spacing-m;
    }
  }
}

.mediaWrapper {
  @include box(100%);
}

.bannerLink {
  @include box(100%);
  display: inline-block;
  cursor: pointer;
  @include media(sp) {
    &:last-child {
      margin-right: $spacing-m;
    }
  }

  .image {
    border-radius: $border-radius-8px;
  }
}

.carousel {
  margin: unset;
  max-width: unset;

  .contentsContainer {
    margin: 0;
  }
}
