@import '~@travel-ui/styles/src/utility';

.wrapper {
  display: block;
}

.card {
  position: relative;
  z-index: 0;
}

.info,
.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

.image > svg {
  margin: 0 auto;
}

.info {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: $zIndex-overlay2;
  padding: $spacing-m;
}

.image {
  z-index: $zIndex-overlay1;
}

.topHalf,
.bottomHalf {
  flex-grow: 1;
}

.bottomHalf {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.topHalf {
  display: flex;

  .topLeft,
  .topRight {
    flex-grow: 1;
  }

  .topRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
